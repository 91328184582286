import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { fetchGetAuthHttp } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { encode as base64_encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../Components/Awareness";

let adNetwork = "";
const AwarenessScreen = (props) => {
  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");
  useEffect(() => {
    if (props.token) {
      checkUtm();
      getAuthFlow();
    }
  }, [props.token]);
  useEffect(() => {
    gtmInitialization();
  }, []);

  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-WSBVX5P",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const checkUtm = async () => {
    try {
      let response = await fetchGetAuthHttp(ApiNames.utm, props.token);

      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              // setSubUtm("econceptions");
              // setAdNetwork(e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getAuthFlow = async () => {
    try {
      tagManagerEvents("Request_Initiated", utm_source);
      let response = await fetchGetAuthHttp(
        ApiNames.awarenessFlow + "?utm_source=" + utm_source,
        props.token
      );
      if (response.status === 200) {
        if (response.response.result === 2 || response.response.result === 0) {
          if (
            adNetwork.toLowerCase() === "tiktok" ||
            adNetwork.toLowerCase() === "tik tok"
          ) {
            tagManagerEvents("subscribe", "econTikTok");
          } else if (adNetwork.toLowerCase() === "google") {
            tagManagerEvents("subscribe", "econceptions");
          } else {
            tagManagerEvents("subscribe", utm_source);
          }
          tagManagerEvents("Request_Success", utm_source);
          let url = window.location.origin;
          let encodedMsisdn = base64_encode(response.response.msisdn);

          url = `${url}/home?ndsism=${encodedMsisdn}`;
          if (url.includes("http:")) {
            url = url.replace("http", "https");
          }
          window.location.replace(url);
        } else {
          tagManagerEvents("Request_Failed", utm_source);
          navigateToLanding();
        }
      } else {
        tagManagerEvents("Request_Failed", utm_source);
        navigateToLanding();
        console.log("Something went wrong.");
      }
    } catch (err) {
      tagManagerEvents("Request_Failed", utm_source);
      navigateToLanding();
      console.log("Error :" + err);
    }
  };

  const navigateToLanding = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
